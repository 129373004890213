<template>
  <div>
    <h4 style="margin-bottom: 10px">
      {{ vendor.vendor_name || vendor.business_name }}
    </h4>
    <vdtnet-table
      id="monthly_forecasts"
      :fields="fields"
      :opts="opts"
      ref="monthly_forecasts"
      class-name="table table-bordered nowrap w-100"
    >
    </vdtnet-table>
  </div>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
export default {
  props: {
    vendor: {
      type: Object,
      required: true,
    },
  },
  components: {
    VdtnetTable,
  },
  data() {
    return {
      fields: {
        filterable_month_date: {
          label: "Month",
          sortable: false,
          searchable: false,
          render: (data, type, row, meta) => {
            return moment(row.filterable_month_date, "YYYY-MM-DD").format(
              "MMMM Y"
            );
          },
        },
        actions: {
          label: " ",
          sortable: false,
          searchable: false,
          render: (data, type, row, meta) => {
            if (new Date(row.filterable_month_date) >= new Date("2021-03-01")) {
              return `<a href="/v2/vendors/${this.vendor.id}/forecast_exports/${row.date}" class="btn btn-default btn-sm" target="blank">Download CSV</a>`;
            }
            return null;
          },
        },
      },
      opts: {
        ajax: {
          url: `/v2/vendors/${this.vendor.id}/forecast_exports`,
          type: "GET",
        },
        searching: true,
        searchDelay: 1500,
        serverSide: true,
        processing: true,
      },
      quickSearch: "",
    };
  },
  methods: {},
};
</script>
