<template>
  <div class="btn-group">
    <button
      class="btn btn-primary dropdown-toggle"
      type="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      Send Invoice <span class="caret"></span>
    </button>
    <ul class="dropdown-menu">
      <li>
        <a href="#" @click.prevent="sendInvoice" :disabled="loading">
          Send Invoice with PDF & CSV
        </a>
      </li>
      <li>
        <a
          href="#"
          @click.prevent="sendInvoice(true, false)"
          :disabled="loading"
        >
          Send Invoice with PDF
        </a>
      </li>
      <li>
        <a
          href="#"
          @click.prevent="sendInvoice(false, true)"
          :disabled="loading"
        >
          Send Invoice with CSV
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    initial_invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      invoice: this.initial_invoice,
    };
  },
  methods: {
    async sendInvoice(pdf = true, csv = true) {
      this.$confirm("Are you sure to send this invoice?", "Warning", {
        confirmButtonText: "Send",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(async (response) => {
          try {
            this.loading = true;
            const res = await axios.post(`/v2/invoices/`, {
              invoice_ids: [this.invoice.id],
              send_pdf: pdf,
              send_csv: csv,
            });
            this.loading = false;
            this.$message({
              showClose: true,
              message: "Invoice Sent",
              type: "success",
            });
          } catch (err) {
            console.error(err);
            this.loading = false;
            this.$message({
              showClose: true,
              message: "Error Invoice Sending",
              type: "error",
            });
          }
        })
        .catch((_) => {});
    },
  },
};
</script>
