<template>
  <div>
    <input type="hidden" :value="date" :name="name" />
    <el-date-picker
      v-model="date"
      type="date"
      placeholder="Pick a day"
      format="dd/MM/yyyy"
      value-format="yyyy-MM-dd"
      :disabled="disabled"
      :id="elementId"
      style="width:100%;"
    >
    </el-date-picker>
  </div>
</template>
<script>
export default {
  props: {
    elementId: {
      type: String,
      required: false,
    },
    initial_disabled: {
      type: [Boolean, String, Number],
    },
    name: {
      type: String,
      required: true,
    },
    initial_date: {
      type: String,
    },
  },
  data() {
    return {
      disabled: this.initial_disabled,
      date: this.initial_date || null,
    };
  },
};
</script>
<style lang="sass">
input#last_service_date:disabled
  color: #F44336
</style>
