<template>
  <div>
    <vdtnet-table
      id="vendors-table"
      :fields="fields"
      :opts="opts"
      ref="vendor_charge_table"
      class-name="table table-bordered nowrap w-100"
    >
    </vdtnet-table>
  </div>
</template>
<script>
import VdtnetTable from "vue-datatables-net";
export default {
  props: {
    initial_vendor: {
      type: Object,
      required: true,
    },
  },
  components: {
    VdtnetTable,
  },
  data() {
    return {
      fields: {
        month: { label: "Month" },
        total_weighted_price: {
          label: "Rate (inc GST if applicable)",
          sortable: false,
          render: function(data, type, row, meta) {
            return row.inc_gst_price;
          },
        },
      },
      opts: {
        ajax: {
          url: `/v2/vendors/${this.initial_vendor.id}/mcrs`,
          type: "GET",
        },
        searching: true,
        searchDelay: 1500,
        serverSide: true,
        processing: true,
      },
      quickSearch: "",
      vendor: this.initial_vendor,
    };
  },
  methods: {
    doSearch() {
      this.$refs.vendor_charge_table.search(this.quickSearch);
    },
  },
};
</script>
