<template>
  <div class="container-fluid">
    <div class="capt-table-head row table-row">
      <div class="col-md-3 table-column">Date Range</div>
      <div class="col-md-3 table-column">Number of Invoices</div>
      <div class="col-md-3 table-column">Total Number of Jobs</div>
      <div class="col-md-3 table-column">Total Sales</div>
    </div>

    <div
      class="row table-row clickable bordered"
      v-for="group in invoiceGroups"
      :key="group.date"
      @click="goTo(group)"
    >
      <div class="col-md-3 table-column">
        <p>{{ group.data_range }}</p>
      </div>
      <div class="col-md-3 table-column">
        <p>
          {{ group.no_invoices }}
        </p>
      </div>
      <div class="col-md-3 table-column">
        <p>
          {{ group.no_jobs }}
        </p>
      </div>
      <div class="col-md-3 table-column">
        <p>${{ Number(group.total_sales).toFixed(2) }}</p>
      </div>
    </div>
    <div
      class="row table-row bordered"
      v-if="lastItem && new Date(lastItem.date) > maxDate"
      v-loading="loading"
    >
      <div class="col-md-12 table-column centered" style="margin-bottom:0px;">
        <a href="#" @click.prevent="loadMore">Load More</a>
      </div>
    </div>
    <div class="row table-row" v-if="invoiceGroups.length == 0">
      <div class="col-md-12 table-column">
        You have no invoices yet!
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    initial_data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      invoiceGroups: this.initial_data || [],
      loading: false,
      maxDate: new Date("2020-01-01"),
    };
  },
  methods: {
    goTo(group) {
      location.href = `/invoices?month_date=${group.date}`;
    },
    async loadMore() {
      try {
        this.loading = true;
        const res = await axios.post(`/v2/invoice_groups`, {
          max_date: this.lastItem.date,
        });
        const groups = res.data.reverse();
        this.invoiceGroups.push(...groups);
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },
  },
  computed: {
    lastItem() {
      const [lastItem] = this.invoiceGroups.slice(-1);
      return lastItem;
    },
  },
};
</script>

<style lang="sass" scoped>
.table-row
    padding-top: 10px
    padding-bottom: 10px
    display: flex
    align-items: center
    justify-content: flex-start
    &.clickable
        cursor: pointer
        &:hover
          background: #efefef
    &.bordered
        border-bottom: 1px solid #efefef


.table-column
    p, a
        margin-bottom: 0px

    &.centered
        display: flex
        align-items: center
        justify-content: center
</style>
