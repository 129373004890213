<template>
  <el-dialog :visible.sync="openModal" title="Select Company">
    <div id="companies-body">
      <div class="row">
        <div
          class="col-12 company-row"
          v-for="company in companies"
          :key="company.id"
          :class="{ selected: selectedCompanyId == company.id }"
          @click="selectCompany(company.id)"
        >
          {{ company.name }}
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import axios from "axios";
export default {
  props: {
    companies: {
      type: Array,
      required: true,
    },
    selectedCompanyId: {
      type: Number,
      required: false,
    },
    open_modal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      openModal: false,
    };
  },
  methods: {
    async selectCompany(id) {
      try {
        const res = await axios.post(`/select-company`, { company_id: id });
        window.location.href = "/";
      } catch (err) {
        this.$message({
          showClose: true,
          message: "Error Changing Company",
          type: "error",
        });
        console.error(err);
      }
    },
  },
  watch: {
    open_modal(to, from) {
      this.openModal = to;
    },
    openModal(to, from) {
      this.$emit("update:open_modal", to);
    },
  },
};
</script>
<style lang="sass" scoped>
.company-row
    margin:
        left: 10px
        right: 10px
        bottom: 15px
    display: flex
    align-content: center
    justify-content: flex-start
    padding:
        left: 10px
        right: 10px
        top: 10px
        bottom: 10px
    background: #efefef
    border-radius: 25px
    cursor: pointer

    &.selected
        color: #fff
        background: #2a2e81
#companies-body
    width: 100%
</style>
