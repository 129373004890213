<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="btn-group">
          <button
            class="btn btn-default btn-sm dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Actions <span class="caret"></span>
          </button>
          <send-bulk-invoices></send-bulk-invoices>
        </div>
      </div>
      <div class="col-md-6">
        <div
          style="display:flex; justify-content:flex-end; align-items:center;"
        >
          <el-select
            v-model="voidFilter"
            placeholder="Filter by Status"
            @change="filterVoidStatus"
          >
            <el-option
              v-for="status in statuses"
              :key="status.value"
              :label="status.label"
              :value="status.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top:20px;">
      <div class="col-md-12">
        <form
          class="form-inline"
          style="display:flex; justify-content:flex-end; align-items:center; margin-bottom:20px;"
          @submit.stop.prevent="doSearch"
        >
          <div class="input-group" style="display:flex; align-items:center;">
            <input
              v-model="quickSearch"
              type="search"
              placeholder="Quick search"
              style="margin-right:10px;"
              class="form-control"
            />
            <div class="input-group-append">
              <button type="submit" class="btn btn-outline-secondary">
                <i class="mdi mdi-magnify" /> Go
              </button>
            </div>
          </div>
        </form>

        <vdtnet-table
          id="invoices-table"
          :fields="fields"
          v-loading="loading"
          :opts="opts"
          ref="invoices_table"
          class-name="table table-bordered nowrap w-100"
          style="width:100%;"
        >
        </vdtnet-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VdtnetTable from "vue-datatables-net";
export default {
  props: {
    monthDate: {
      type: String,
      required: true,
    },
    voidFilter: {
      type: String,
    },
  },
  components: {
    VdtnetTable,
  },
  data() {
    return {
      fields: {
        "": {
          label: "",
          searchable: false,
          sortable: false,
          render: (data, type, row, meta) => {
            return `<input type="checkbox" onchange="addInvoiceItem(${row.id})"/>`;
          },
        },
        id: {
          label: "ID",
          render: (data, type, row, meta) => {
            return `<a href="/invoices/${row.id}">${row.id}</a>`;
          },
        },
        "client.name": {
          label: "CLIENT NAME",
          render: (data, type, row, meta) => {
            return `<a href="/invoices/${row.id}">${this.getInvoiceName(
              row
            )}</a>`;
          },
        },
        invoice_job_records_count: {
          label: "NUMBER OF JOBS",
          sortable: false,
          searchable: false,
        },
        invoice_job_records_price_count: {
          label: "TOTAL AMOUNT",
          render: (data, type, row, meta) => {
            return `$${Number(
              row.invoice_job_records_price_count *
                (1 + row.client.company.tax / 100) || 0
            ).toFixed(2)}`;
          },
          sortable: false,
          searchable: false,
        },
        status: {
          label: "STATUS",
          searchable: false,
          sortable: false,
          render: (data, type, row, meta) => {
            if (row.deleted_at) return "Voided";
            else if (row.custom) return "Custom";
            else return "Unpaid";
          },
        },
      },
      opts: {
        ajax: {
          url: `/v2/invoices/${this.monthDate}/${this.voidFilter}/data`,
          type: "GET",
          dataSrc: function(json) {
            return json.data;
          },
        },
        searching: true,
        searchDelay: 1500,
        serverSide: true,
        processing: true,
        responsive: true,
      },
      quickSearch: "",
      loading: false,
      statuses: [
        {
          label: "All",
          value: "all",
        },
        {
          label: "Voided",
          value: "voided",
        },
        {
          label: "Unvoided",
          value: "unvoided",
        },
      ],
    };
  },
  methods: {
    getInvoiceName(row) {
      return row.entity ? row.entity.name : row.client.name;
    },
    doSearch() {
      this.$refs.invoices_table.search(this.quickSearch);
    },
    async loadData(cb) {
      try {
        this.loading = true;
        const res = await axios.get(
          `/v2/invoices/${this.monthDate}/${this.voidFilter}/data`
        );
        this.loading = false;
        cb(res.data);
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    filterVoidStatus(value) {
      let url = new URL(window.location.href);
      url.searchParams.set("status", value);
      location.href = url.href;
    },
  },
};
</script>
