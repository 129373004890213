<template>
  <div>
    <el-dialog
      title="Safety & Quality Issue"
      :visible.sync="visible"
      :before-close="onClose"
      width="30%"
    >
      <div class="row modal-container">
        <div class="col-12">
          <label class="control-label">
            TITLE
          </label>
          <el-input :value="safety.job.name" :disabled="true"> </el-input>
        </div>
        <div class="col-12" style="margin-top:10px;">
          <label class="control-label">
            OUTCOME
          </label>
          <el-select v-model="safety.safety_status" style="width:100%;">
            <el-option
              v-for="issueType in issueTypes"
              :key="issueType.key"
              :value="issueType.key"
              :label="issueType.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-12" style="margin-top:10px;">
          <label class="control-label">
            DESCRIPTION
          </label>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="Please input Comment"
            v-model="safety.issues"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="update">Update</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import IssueType from "../../constants/IssueType";
import axios from "axios";
export default {
  props: {
    company: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      visible: false,
      safety: {
        id: null,
        job: {
          name: null,
        },
      },
    };
  },
  mounted() {
    window.addEventListener("open-safety-modal", async (event) => {
      this.safety = await this.getSafety(event.detail);
      this.visible = true;
    });
  },
  watch: {},
  methods: {
    onClose() {
      this.visible = false;
      this.safety = {
        id: null,
        job: {
          name: null,
        },
      };
    },
    async update() {
      try {
        const res = await axios.put(
          `/qualities/${this.safety.id}`,
          this.safety
        );
        this.$message({
          type: "success",
          message: "Safety Updated",
        });
        this.onClose();
        this.$emit("reload-table", true);
      } catch (err) {
        console.error(err);
        this.$message({
          type: "error",
          message: "Error updating safety",
        });
      }
    },
    async getSafety(id) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await axios.get(
            `/v2/companies/${this.company.id}/safeties/${id}`
          );
          resolve(res.data);
        } catch (err) {
          reject(err);
        }
      });
    },
  },
  computed: {
    issueTypes() {
      return Object.keys(IssueType).map((issueType) => {
        return { key: issueType, value: IssueType[issueType].name };
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.modal-container
    margin:
        left: 10px
        right: 10px
</style>
