<template>
  <div>
    <div class="form-group col-md-6">
      <label class="col-md-3" for="name">Apply Fixed Price</label>
      <div class="col-md-9">
        <el-checkbox
          v-model="fixedPrice"
          :disabled="disabled"
          id="use_fixed_price"
        >
        </el-checkbox>
        <input
          type="hidden"
          name="job[use_fixed_price]"
          :value="isUseFixedPrice"
        />
      </div>
    </div>
    <div class="form-group col-md-6">
      <label class="col-md-3" for="name">Fixed Price</label>
      <div class="col-md-9">
        <el-input
          type="number"
          id="fixed_price"
          name="job[fixed_price]"
          min="0.00"
          step="0.01"
          :disabled="disabled || !fixedPrice"
          v-model="price"
        >
        </el-input>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    initial_disabled: {
      required: true,
      type: [String, Boolean],
    },
    initial_job: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      fixedPrice: this.initial_job
        ? this.initial_job.use_fixed_price
          ? true
          : false
        : false,
      price: this.initial_job ? this.initial_job.fixed_price || 0 : 0,
      disabled: this.initial_disabled || false,
    };
  },
  computed: {
    isUseFixedPrice() {
      return this.fixedPrice ? 1 : 0;
    },
  },
};
</script>
