<template>
  <div>
    <div
      style="display:flex; justify-content:space-between; flex-direction:column;"
    >
      <label for="name">Reference</label>
      <a href="#" @click.prevent="updateReference = true">
        Update Invoice Reference
      </a>
    </div>
    <update-invoice-ref-number-modal
      :initial_invoice="invoice"
      :open.sync="updateReference"
    ></update-invoice-ref-number-modal>
  </div>
</template>

<script>
export default {
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      updateReference: false,
    };
  },
};
</script>
