<template>
  <div>
    <div class="btn-group">
      <button
        class="btn btn-default btn-sm dropdown-toggle"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Actions <span class="caret"></span>
      </button>
      <ul class="dropdown-menu">
        <li><a :href="updateLink">Modify</a></li>
        <li>
          <a @click.prevent="deleteRule" href="#">Remove</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    updateLink: {
      type: String,
      required: true,
    },
    job: {
      type: Object,
      required: true,
    },
    frequencyRule: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async deleteRule() {
      this.$confirm(
        "Are you sure you want to remove the selected rule?",
        "Warning",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "danger",
        }
      )
        .then(async (response) => {
          try {
            const res = await axios.delete(
              `/v2/jobs/${this.job.id}/frequency_rules/${this.frequencyRule.id}`
            );
            console.log(res);
            location.reload(); // reload it
          } catch (err) {
            console.error(err);
          }
        })
        .catch((_) => {});
    },
  },
};
</script>
