<template>
  <div>
    <p v-if="frequency.interval == 2 && frequency.freq == 'WEEKLY'">
      Fortnightly, on
      <span v-for="(day, index) in frequency.week_days" :key="index">
        {{ getDay(day) }}
      </span>
    </p>
    <p v-else>
      Every {{ frequency.interval }} {{ getFrequencyText(frequency.freq) }}, on
      <span v-for="(day, index) in frequency.week_days" :key="index">
        {{ getDay(day)
        }}{{ frequency.week_days.length - 1 == index ? "" : "," }}
      </span>
      <span v-if="frequency.setpos">
        <span
          v-for="(setpos, index) in frequency.setpos.split(',')"
          :key="index"
          >the {{ frequency.setpos + getTh(frequency.setpos)
          }}{{ frequency.week_days.length - 1 == index ? "" : "," }} week
        </span>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    frequency: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getTh(setpos) {
      switch (setpos) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    },
    getFrequencyText(freq, interval) {
      switch (freq) {
        case "DAILY":
          return `day${interval > 1 ? "s" : ""}`;
        case "WEEKLY":
          return `week${interval > 1 ? "s" : ""}`;
        case "MONTHLY":
          return `month${interval > 1 ? "s" : ""}`;
        case "YEARLY":
          return `year${interval > 1 ? "s" : ""}`;
      }
    },
    getDay(day) {
      switch (day) {
        case "MO":
          return "Monday";
        case "TU":
          return "Tuesday";
        case "WE":
          return "Wednesday";
        case "TH":
          return "Thursday";
        case "FR":
          return "Friday";
        case "SA":
          return "Saturday";
        case "SU":
          return "Sunday";
      }
    },
  },
};
</script>
