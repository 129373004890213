<template>
  <div class="panel panel-default">
    <div class="panel-heading">
      <div class="row">
        <div class="col-sm-8">
          History
        </div>
        <div class="col-sm-4 text-right">
          <button
            type="button"
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#notes-modal"
          >
            Add History
          </button>
        </div>
      </div>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-md-12">
          <form
            class="form-inline"
            style="display:flex; justify-content:flex-end; align-items:center; margin-bottom:20px;"
            @submit.stop.prevent="doSearch"
          >
            <div class="input-group" style="display:flex; align-items:center;">
              <input
                v-model="quickSearch"
                type="search"
                placeholder="Quick search"
                style="margin-right:10px;"
                class="form-control"
              />
              <div class="input-group-append">
                <button type="submit" class="btn btn-outline-secondary">
                  <i class="mdi mdi-magnify" /> Go
                </button>
              </div>
            </div>
          </form>
          <vdtnet-table
            id="history-table"
            :fields="fields"
            :opts="opts"
            ref="history_table"
            class-name="table table-bordered nowrap w-100"
          >
          </vdtnet-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VdtnetTable from "vue-datatables-net";
export default {
  props: {
    historyLink: {
      type: String,
      required: true,
    },
  },
  components: {
    VdtnetTable,
  },
  data() {
    return {
      fields: {
        created_at: {
          label: "Date",
          sortable: false,
          render: (data, type, row, meta) => {
            return moment(row.created_at, "YYYY-MM-DD HH:mm:ss").format(
              "DD/MM/Y HH:mm:ss"
            );
          },
        },
        context: {
          label: "Context",
          sortable: false,
          searchable: false,
          render: (data, type, row, meta) => {
            return row.context || "System Log";
          },
        },
        key: {
          label: "Interaction",
          sortable: false,
          render: (data, type, row, meta) => {
            if (row.user && row.key) {
              if (row.key == "created_at" && !row.old_value)
                return `${row.user.first_name} created this resource at ${row.new_value}`;
              else
                return `${row.user.first_name} changed ${row.key} from ${row.old_value}`;
            } else if (row.user && !row.key) {
              return `${row.user.first_name} added the following note <br /> ${row.notes}`;
            } else if (!row.user) {
              if (row.key == "created_at" && !row.old_value)
                return `The database seeder created this resource at ${row.new_value}`;
              else
                return `The database seeder changed ${row.key} from ${row.old_value} to ${row.new_value}`;
            }
          },
        },

/*         "": {
          label: "",
          sortable: false,
          searchable: false,
          render: (data, type, row, meta) => {
            if (row.context) {
              return `
                <div class="btn-group">
                    <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Actions<span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu">
                        <li><a class="resource-actions" data-target="#delete-note-modal" href="/v2/revisions/${row.id}">Delete</a></li>
                    </ul>
                </div>`;
            }
            return null;
          },
        }, */
      },
      opts: {
        ajax: {
          url: this.historyLink,
          type: "GET",
        },
        searching: true,
        searchDelay: 1500,
        serverSide: true,
        processing: true,
      },
      quickSearch: "",
    };
  },
  methods: {
    doSearch() {
      this.$refs.history_table.search(this.quickSearch);
    },
  },
};
</script>
