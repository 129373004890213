<template>
  <div>
    <vdtnet-table
      id="records_table"
      :fields="fields"
      :opts="opts"
      ref="subcontractors_table"
      class-name="table table-bordered nowrap w-100"
    >
    </vdtnet-table>
    <subcontractor-vendor-modal :company="company">
    </subcontractor-vendor-modal>
  </div>
</template>
<script>
import VdtnetTable from "vue-datatables-net";
export default {
  props: {
    vendor: {
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
  },
  components: {
    VdtnetTable,
  },
  data() {
    return {
      fields: {
        id: { label: "ID" },
        subcontractor: {
          label: "Subcontractor",
          sortable: true,
          render: function(data, type, row, meta) {
            return `${row.user.first_name} ${row.user.last_name}`;
          },
        },
        status: {
          label: "STATUS",
          sortable: false,
          render: function(data, type, row, meta) {
            if (row.is_active) {
              return `<td><span class="label label-success">Active<span></td>`;
            } else {
              return `<td><span class="label label-warning">Inactive<span></td>`;
            }
          },
        },
        actions: {
          label: "",
          sortable: false,
          render: function(data, type, row, meta) {
            return `<button class="btn btn-primary" onclick="openVendor(${row.user.id})">Change to vendor</button>`;
          },
        },
      },
      opts: {
        ajax: {
          url: `/v2/vendors/${this.vendor.id}/subcontractors`,
          type: "GET",
        },
        searching: true,
        searchDelay: 1500,
        serverSide: true,
        processing: true,
      },
      quickSearch: "",
    };
  },
  methods: {},
};
</script>
