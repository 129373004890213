<template>
  <div>
    <input type="hidden" name="user[company_ids]" v-model="user.company_ids" />
    <el-select
      multiple
      v-model="user.company_ids"
      style="width:100%;"
      :disabled="disabled"
    >
      <el-option
        v-for="company in companies"
        :key="company.id"
        :label="company.name"
        :value="company.id"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  props: {
    initial_companies: {
      required: true,
      type: Array,
    },
    initial_user: {
      required: true,
      type: Object,
    },
    initial_disabled: {
      required: false,
      type: [Number, String, Boolean],
    },
  },
  data() {
    return {
      user: this.initial_user,
      companies: this.initial_companies,
      disabled: this.initial_disabled ? true : false,
    };
  },
  mounted() {
    if (this.user.companies) {
      this.user.company_ids = this.user.companies.map((company) => company.id);
    } else {
      this.user.company_ids = [];
    }
  },
};
</script>
