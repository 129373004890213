<template>
  <div class="checkbox-group">
    <el-checkbox v-model="value" :disabled="disabled" :id="id"> </el-checkbox>
    <input type="hidden" :name="name" :value="value ? 1 : 0" />
  </div>
</template>
<script>
export default {
  props: {
    id: {
      required: false,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    initial_value: {
      type: [Number, Boolean, String],
    },
    initial_disabled: {
      type: [Boolean, String, Number],
    },
  },
  data() {
    return {
      disabled: this.initial_disabled ? true : false,
      value: this.initial_value ? true : false,
    };
  },
  watch: {
    value(to, from) {
      let event = new CustomEvent(`update-checkbox-value-${this.id}`, {
        detail: {
          value: to,
        },
      });
      document.dispatchEvent(event);
    },
  },
};
</script>
<style lang="sass" scoped>
.checkbox-group
  margin-top: 10px
  margin-bottom: 10px
</style>
