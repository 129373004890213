<template>
  <div>
    <div class="selected-company-container" @click="changeCompany">
      <p v-if="selectedCompany" class="selected-company-label">
        <i class="fas fa-random" style="margin-right:5px;"></i>
        {{ selectedCompany ? selectedCompany.name : "Select Company" }}
      </p>
    </div>
    <companies-modal
      :companies="companies"
      :open_modal.sync="openModal"
      :selected-company-id="selectedCompanyId"
    >
    </companies-modal>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    selectedCompanyId: {
      required: false,
      type: Number,
    },
  },
  mounted() {
    this.getCompanies();
  },
  data() {
    return {
      companies: [],
      openModal: false,
    };
  },
  methods: {
    async getCompanies() {
      try {
        const res = await axios.get(`/v2/companies`);
        this.companies = res.data;
      } catch (err) {
        console.error(err);
      }
    },
    changeCompany() {
      this.openModal = true;
    },
  },
  computed: {
    selectedCompany() {
      return this.companies.find(
        (company) => company.id == this.selectedCompanyId
      );
    },
  },
};
</script>
<style lang="sass" scoped>
.selected-company-container
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
.selected-company-label
    color: #fff
    margin-top: 10px
    margin-bottom: 10px
    font-weight: 500
    cursor: pointer
</style>
