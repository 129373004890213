<template>
  <div>
    <p>
      Picture Taken At:
      <span class="ts-row">{{ dateTime }}</span>
    </p>
  </div>
</template>
<script>
export default {
  props: {
    initial_ts: {
      type: String,
      required: true,
    },
    datetimeOriginal: {
      type: String,
      required: false,
    },
    version: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      dateTime: null,
    };
  },
  mounted() {
    if (!this.version) {
      this.dateTime = moment
        .unix(this.initial_ts)
        .utc()
        .format("DD/MM/Y HH:mm:ss");
    } else if (this.version >= 2) {
      // new version, use the datetime original
      this.dateTime = moment(
        this.datetimeOriginal,
        "YYYY-MM-DD HH:mm:ss"
      ).format("DD/MM/Y HH:mm:ss");
    }
  },
};
</script>
