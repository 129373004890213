<template>
  <div>
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <el-select
        v-model="job.job_type_id"
        style="width: 100%"
        filterable
        :disabled="disabled"
        @change="changeValue"
        clearable
        @clear="job.job_type_id = null"
      >
        <el-option
          v-for="type in types"
          :key="type.id"
          :label="type.job_type_name"
          :value="type.id"
        >
        </el-option>
      </el-select>
    </div>
    <input type="hidden" :value="job.job_type_id" :name="name" />
  </div>
</template>
<script>
export default {
  props: {
    types: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    initial_job: {
      type: Object,
      required: false,
      default: () => {
        return {
          job_type_id: null,
        };
      },
    },
    initial_disabled: {
      type: [String, Number, Boolean],
    },
  },
  data() {
    return {
      job: this.initial_job,
      disabled: this.initial_disabled ? true : false,
    };
  },
  mounted() {},
  methods: {
    changeValue(value) {
      let obj = this.types.find((type) => type.id == value);

      if (obj) {
        // gc only
        let event = new CustomEvent("set-general-cleaning", {
          detail: {
            general_cleaning: obj.job_type_name
              .trim()
              .toLowerCase()
              .includes("general cleaning"),
          },
        });
        document.dispatchEvent(event);
      }
    },
  },
};
</script>