<template>
  <div>
    <h4 style="margin-bottom: 10px">
      {{ vendor.vendor_name || vendor.business_name }}
    </h4>
    <vdtnet-table
      id="historical_mcr"
      :fields="fields"
      :opts="opts"
      ref="historical_mcr"
      class-name="table table-bordered nowrap w-100"
    >
    </vdtnet-table>
  </div>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
export default {
  props: {
    vendor: {
      type: Object,
      required: true,
    },
  },
  components: {
    VdtnetTable,
  },
  data() {
    return {
      fields: {
        month: { label: "Month" },
        total_weighted_price: {
          label: "Rate (inc GST if applicable)",
          sortable: false,
          render: function (data, type, row, meta) {
            return row.inc_gst_price;
          },
        },
        actions: {
          label: " ",
          sortable: false,
          render: (data, type, row, meta) => {
            if (
              new Date(row.date) >= new Date("2020-09-01") &&
              new Date(row.date) < new Date("2021-02-01")
            ) {
              return `<a href="/v2/vendors/${this.vendor.id}/mcrs/csv?period_date=${row.date}" class="btn btn-default btn-sm" target="blank">Download CSV</a>`;
            } else if (new Date(row.date) >= new Date("2021-02-01")) {
              return `<a href="/v2/vendors/${this.vendor.id}/mcrs/csv?period_date=${row.date}&version=2" class="btn btn-default btn-sm" target="blank">Download CSV</a>`;
            }
            return null;
          },
        },
      },
      opts: {
        ajax: {
          url: `/v2/vendors/${this.vendor.id}/mcrs`,
          type: "GET",
        },
        searching: true,
        searchDelay: 1500,
        serverSide: true,
        processing: true,
      },
      quickSearch: "",
    };
  },
  methods: {},
};
</script>
