<template>
  <div>
    <div
      class="form-inline"
      style="
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 20px;
      "
    >
      <div style="display: flex; align-items: center" class="input-group">
        <el-input
          placeholder="Quick search"
          @change="doSearch"
          type="text"
          style="margin-right: 10px"
          v-model="quickSearch"
          clearable
          @clear="doSearch"
        >
        </el-input>
        <div class="input-group-append">
          <button
            type="submit"
            class="btn btn-outline-secondary"
            @click="doSearch"
          >
            <i class="mdi mdi-magnify" /> Go
          </button>
        </div>
      </div>
    </div>
    <vdtnet-table
      id="vendors-table"
      :fields="fields"
      :opts="opts"
      class-name="table table-bordered nowrap w-100"
      ref="vendor_table"
    >
    </vdtnet-table>
    <historical-mcr> </historical-mcr>
    <monthly-export> </monthly-export>
  </div>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
import HistoricalMcr from "../MCR/HistoricalMcr";
import Vue from "vue";
import MonthlyExport from "../Forecast/MonthlyExport";
export default {
  props: {
    canUpdate: {
      type: Boolean,
    },
  },
  components: {
    VdtnetTable,
    HistoricalMcr,
    MonthlyExport,
  },
  data() {
    return {
      fields: {
        id: { label: "ID" },
        vendor_name: {
          label: "Name",
          render: (data, type, row, meta) => {
            if (row.vendor_name) {
              return `<a href="/vendors/${row.id}">${row.vendor_name}</a>
            <a href="/vendors/${row.id}" class="btn btn-default btn-sm capt-edit-btn">Edit</a>`;
            } else {
              return `<a href="/vendors/${row.id}">${row.user.first_name} ${row.user.last_name}</a>
            <a href="/vendors/${row.id}" class="btn btn-default btn-sm capt-edit-btn">Edit</a>`;
            }
          },
        },
        "user.email": { label: "Email" },
        "user.phone": { label: "Phone" },
        address: {
          label: "Address",
          searchable: false,
          sortable: false,
          render: (data, type, row, meta) => {
            return row.address.address_line_1;
          },
        },
        is_active: { label: "Status", searchable: false },
        current_month_forecast: {
          label: "Current Month Forecast",
          sortable: false,
          searchable: false,
          render: (data, type, row, meta) => {
            return `<a onclick="open_information(${row.id})">$${Number(
              row.forecasts.length > 0
                ? Number(row.forecasts[0].forecasted_total)
                : 0
            ).toFixed(2)}</a>`;
            // Number(row.forecasts[0].actual_total) +
          },
        },
        next_month_forecast: {
          label: "Next Month Forecast",
          sortable: false,
          searchable: false,
          render: (data, type, row, meta) => {
            return `$${Number(
              row.next_month_forecasts.length > 0
                ? row.next_month_forecasts[0].total
                : 0
            ).toFixed(2)}`;
          },
        },
        charge_rate: {
          label: "Last Month Charge Rate",
          sortable: false,
          searchable: false,
          render: (data, type, row, meta) => {
            return `$${Number(
              row.charge_rate_values.length > 0
                ? row.charge_rate_values[0].value
                : 0
            ).toFixed(2)}`;
          },
        },
        actions: {
          isLocal: true,
          label: "Actions",
          render: (data, type, row, meta) => {
            let activeButtons = `
                      <li><a class="resource-actions" data-target="#deactivate-modal" href="/vendors/${row.id}/deactivate">Deactivate</a></li>
                      <li><a onclick="openMcr(${row.id})" style="cursor:pointer;">Download Historical MCR</a></li>
                      <li><a onclick="openForecast(${row.id})" style="cursor:pointer;">Download Forecast</a></li>
                      <li><a href="/vendors/${row.id}/schedules">Download Schedule</a></li>`;

            let deactiveButtons = `
                <li><a class="resource-actions" data-target="#activate-modal" href="/vendors/${row.id}/activate">Activate</a></li>
                <li><a onclick="openMcr(${row.id})" style="cursor:pointer;">Download Historical MCR</a></li>`;

            let deleteButtons = `<li role="separator" class="divider"></li>
                      <li><a class="resource-actions" data-target="#delete-modal" href="/vendors/${row.id}/delete">Delete</a></li>`;
            return `
              <div class="btn-group">
                  <button class="btn btn-default btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Actions <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu">
                      ${
                        row.is_active == "Inactive"
                          ? deactiveButtons
                          : activeButtons
                      }

                     ${row.deleted_at ? "" : deleteButtons}
                  </ul>
                </div>
            `;
          },
        },
      },
      opts: {
        ajax: {
          url: "/vendors/data",
          type: "GET",
        },
        searching: true,
        searchDelay: 1500,
        serverSide: true,
        processing: true,
      },
      quickSearch: "",
    };
  },
  mounted() {
    window.addEventListener("open-information", this.alert);
  },
  beforeDestroy() {
    window.removeEventListener("open-information", this.alert);
  },

  methods: {
    alert() {
      this.$alert(
        "The forecast summary in this view might not be the latest version and could be different compared to the export result, it is refreshed every 30 minutes.",
        "Important Information",
        {
          confirmButtonText: "OK",
        }
      );
    },
    doSearch() {
      this.$refs.vendor_table.search(this.quickSearch);
    },
  },
};
</script>