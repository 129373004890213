<template>
  <div class="right">
    <span v-if="!invoice.deleted_at">
      <button class="btn btn-danger" @click="deleteInvoice" v-loading="loading">
        Void
      </button>
    </span>
    <span v-else>
      <button
        class="btn btn-danger"
        @click="restoreInvoice"
        v-loading="loading"
      >
        Voided
      </button>
    </span>
    <el-tooltip
      class="item"
      effect="dark"
      content="Force delete only works after invoice is voided"
      placement="bottom"
    >
      <span v-if="invoice.custom">
        <button
          class="btn btn-danger"
          style="margin-left: 10px"
          @click="forceDelete"
          v-loading="loading"
          :disabled="!invoice.deleted_at"
        >
          Force Delete
        </button></span
      >
    </el-tooltip>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    initial_invoice: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      invoice: this.initial_invoice,
      loading: false,
    };
  },
  methods: {
    async forceDelete() {
      this.$confirm(
        "Are you sure you want to delete this invoice permanently?. Once deleted, it cannot be recovered.",
        "Warning",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async (response) => {
          try {
            this.loading = true;
            const res = await axios.delete(
              `/v2/invoices/${this.invoice.id}/force_delete`
            );

            this.$message({
              message: "Invoice Deleted",
              type: "success",
            });
            this.loading = false;
            window.location.replace(`/invoices`);
            window.history.pushState(null, null, `/invoices`);
          } catch (err) {
            this.loading = false;
            console.error(err);
            this.$message({
              showClose: true,
              message: "Error Deleting Invoice",
              type: "error",
            });
          }
        })
        .catch((_) => {});
    },
    async deleteInvoice() {
      this.$confirm(
        "Are you sure you want to remove this invoice?. This will void the invoice in Xero",
        "Warning",
        {
          confirmButtonText: "Void",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async (response) => {
          try {
            this.loading = true;
            const res = await axios.delete(`/v2/invoices/${this.invoice.id}`);
            this.invoice = res.data;
            this.invoice.deleted_at = new Date(); // force add date time
            this.loading = false;
          } catch (err) {
            this.loading = false;
            console.error(err);
            this.$message({
              showClose: true,
              message: "Error Removing Invoice",
              type: "error",
            });
          }
        })
        .catch((_) => {});
    },
    async restoreInvoice() {
      this.$confirm(
        "Are you sure you want to restore this invoice?. Voided invoice in Xero won't be restored.",
        "Warning",
        {
          confirmButtonText: "Restore",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async (response) => {
          try {
            this.loading = true;
            const res = await axios.put(
              `/v2/invoices/${this.invoice.id}/restore`,
              {}
            );
            this.invoice = res.data;
            this.loading = false;
          } catch (err) {
            this.loading = false;
            console.error(err);
            this.$message({
              showClose: true,
              message: "Error Restoring Invoice",
              type: "error",
            });
          }
        })
        .catch((_) => {});
    },
  },
};
</script>
<style scoped lang="sass">
.right
  display: flex
  justify-content: flex-end
  align-items: center
</style>
