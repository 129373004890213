<template>
  <div>
    <div
      style="display: flex; justify-content: space-between; align-items:center;"
    >
      <el-checkbox
        v-model="job.is_credit_note"
        :disabled="disabled"
        @change="changeValue"
      >
      </el-checkbox>
      <p style="margin-bottom:0px;" v-if="job.is_credit_note">
        This will make the job price negative.
      </p>
    </div>
    <input type="hidden" :value="job.is_credit_note" :name="name" />
  </div>
</template>

<script>
export default {
  props: {
    initial_job: {
      type: Object,
      default: () => {
        return {
          is_credit_note: false,
        };
      },
    },
    name: {
      type: String,
      required: true,
    },
    initial_disabled: {
      type: [String, Number, Boolean],
    },
  },
  data() {
    return {
      job: this.initial_job,
      disabled: this.initial_disabled,
    };
  },
  mounted() {
    if (this.job) {
      this.job.is_credit_note = !!this.job.is_credit_note;
    }
    this.disabled = !!this.disabled;
  },
  methods: {
    changeValue(value) {
      let event = new CustomEvent("set-credit-note", {
        detail: {
          is_credit_note: value,
        },
      });
      // alert(value)
      if(value){
        $('#auto_complete_select').select2("val","1");
        this.job.auto_complete = 1;
      }
      else{
        $('#auto_complete_select').select2("val","0");
        this.job.auto_complete = 0;
      }
      document.dispatchEvent(event);
    },
  },
};
</script>
