<template>
  <div>
    <form
      class="form-inline"
      style="display:flex; justify-content:flex-end; align-items:center; margin-bottom:20px;"
      @submit.stop.prevent="doSearch"
    >
      <div class="input-group" style="display:flex; align-items:center;">
        <input
          v-model="quickSearch"
          type="search"
          placeholder="Quick search"
          style="margin-right:10px;"
          class="form-control"
        />
        <div class="input-group-append">
          <button type="submit" class="btn btn-outline-secondary">
            <i class="mdi mdi-magnify" /> Go
          </button>
        </div>
      </div>
    </form>
    <vdtnet-table
      id="missed-job-table"
      :fields="fields"
      :opts="opts"
      ref="missed_job_table"
      class-name="table table-bordered nowrap w-100"
    >
    </vdtnet-table>
  </div>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
export default {
  props: {
    vendor: {
      type: Object,
      required: true,
    },
  },
  components: {
    VdtnetTable,
  },
  data() {
    return {
      fields: {
        "job.id": { label: "Job ID" },
        "record.id": {
          label: "Record ID",
          render: function(data, type, row, meta) {
            return row.record ? row.record.id : "N/A";
          },
        },
        "job.name": { label: "Job Name" },
        "job.type": {label: "Job Type"},
        "job.round.name": { label: "Round" },
        "record.due_date": {
          label: "Due Date",
          render: function(data, type, row, meta) {
            return row.record
              ? moment(row.record.due_date, "YYYY-MM-DD").format("DD/MM/Y")
              : "N/A";
          },
        },
      },
      opts: {
        ajax: {
          url: `/v2/vendors/${this.vendor.id}/missed_jobs`,
          type: "GET",
        },
        searching: true,
        searchDelay: 1500,
        serverSide: true,
        processing: true,
      },
      quickSearch: "",
    };
  },
  methods: {
    doSearch() {
      this.$refs.missed_job_table.search(this.quickSearch);
    },
  },
};
</script>
