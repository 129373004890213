<template>
  <div id="vue-container" v-loading="loading">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="row">
          <div class="col-sm-8" v-cloak>
            Create Invoice
          </div>
        </div>
      </div>

      <div class="panel-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group col-md-6">
              <label class="col-md-3" for="name">Reference</label>
              <div class="col-md-9">
                <input
                  id="form_reference"
                  type="text"
                  name="reference"
                  class="form-control form-data"
                  placeholder="John Doe"
                  v-model="reference"
                />
              </div>
            </div>

            <div class="form-group col-md-6">
              <label class="col-md-3" for="name">Due Date</label>
              <div class="col-md-9">
                <el-date-picker
                  name="due_date"
                  class="form-data"
                  id="datepicker_due_date"
                  type="date"
                  style="width:100%;"
                  v-model="dueDate"
                  value-format="yyyy-MM-dd"
                  format="dd/MM/yyyy"
                >
                </el-date-picker>
              </div>
            </div>

            <div class="form-group col-md-6">
              <label class="col-md-3" for="name">Client</label>
              <div class="col-md-9">
                <el-select
                  id="form_client_id"
                  class="form-data"
                  style="width:100%;"
                  name="client_id"
                  filterable
                  v-model="clientId"
                  @change="getEntities"
                >
                  <el-option
                    :value="client.id"
                    v-for="client in clients"
                    :key="client.id"
                    :label="client.name"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="form-group col-md-6">
              <label class="col-md-3" for="name">Entity</label>
              <div class="col-md-9">
                <el-select
                  id="form_entity_id"
                  class="form-data"
                  style="width:100%;"
                  name="entity_id"
                  filterable
                  :disabled="!clientId"
                  v-model="entityId"
                  clearable
                >
                  <el-option
                    :value="entity.id"
                    v-for="entity in entities"
                    :key="entity.id"
                    :label="entity.name"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="form-group col-md-6">
              <label class="col-md-3" for="type">Type</label>
              <div class="col-md-9">
                <el-select
                  id="invoice_type"
                  class="form-data"
                  style="width:100%;"
                  name="type"
                  v-model="type"
                >
                  <el-option
                    :value="invoiceType.id"
                    v-for="invoiceType in invoiceTypes"
                    :key="invoiceType.id"
                    :label="invoiceType.name"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="row">
          <div class="col-sm-8">
            Invoice Items
          </div>
        </div>
      </div>

      <div class="panel-body">
        <div class="row">
          <div class="col-md-12">
            <!-- start includable table -->
            <div class="">
              <!-- table-responsive -->
              <table class="table">
                <thead class="capt-table-head">
                  <tr>
                    <th>
                      Job
                    </th>
                    <th>Completed on</th>
                    <th>Notes</th>
                    <th>exGST</th>
                    <th>GST</th>
                    <th>Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(record, index) in records" :key="index" v-cloak>
                    <td>
                      <el-select
                        v-model="record.job_id"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="Search Job Name"
                        :remote-method="searchJob"
                        :loading="loading"
                        :disabled="!clientId"
                        @change="(value) => adoptJob(value, record, index)"
                      >
                        <el-option
                          v-for="job in jobs"
                          :key="job.id"
                          :label="`(${job.id}) ${job.name} - ${job.type}`"
                          :value="job.id"
                        >
                        </el-option>
                      </el-select>
                    </td>
                    <td>
                      <el-date-picker
                        :name="`record[${index}][completed_at]`"
                        class="form-data"
                        type="date"
                        style="width:100%;"
                        v-model="record.completed_at"
                        value-format="yyyy-MM-dd"
                        format="dd/MM/yyyy"
                      >
                      </el-date-picker>
                    </td>
                    <td>
                      <input
                        type="text"
                        :name="`record[${index}][notes]`"
                        class="form-control"
                        v-model="record.notes"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        step="0.01"
                        :name="`record[${index}][subtotal]`"
                        class="form-control"
                        v-model="record.subtotal"
                        @input="recalc(record, index)"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        step="0.01"
                        :name="`record[${index}][tax]`"
                        class="form-control"
                        v-model="record.tax"
                        disabled
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        step="0.01"
                        :name="`record[${index}][total]`"
                        class="form-control"
                        v-model="record.total"
                        disabled
                      />
                    </td>
                    <td>
                      <i
                        class="fa fa-times pull-right"
                        @click="removeRecord(record, index)"
                        aria-hidden="true"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end includable table -->
            <button
              @click="storeInvoice"
              class="btn btn-default pull-right"
              style="margin-left: 10px"
            >
              Create Invoice
            </button>

            <button @click="addRecord" class="btn btn-primary pull-right">
              Add Record
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import InvoiceType from "../../constants/InvoiceType";
export default {
  props: {
    clients: {
      type: Array,
      required: true,
    },
    endpoint: {
      type: String,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dueDate: null,
      clientId: null,
      reference: null,
      entityId: null,
      loading: false,
      entities: [],
      type: null,
      loading: false,
      records: [
        {
          completed_at: "",
          notes: "",
          subtotal: 0.0,
          tax: 0.0,
          total: 0.0,
          job_id: null,
        },
      ],
      jobs: [],
    };
  },
  methods: {
    adoptJob(value, record, index) {
      let job = this.jobs.find((job) => job.id == value);
      if (job) {
        record.notes = job.type;
        record.subtotal = job.price;
        this.recalc(record, index);
      }
    },
    async getEntities() {
      try {
        const res = await axios.get(`/v2/clients/${this.clientId}/entities`);
        this.entities = res.data;
      } catch (err) {
        console.error(err);
      }
    },
    recalc(record, index) {
      let tax = this.company.tax / 100;
      record.total = Number(Number(record.subtotal || 0) * (1 + tax)).toFixed(
        2
      );

      // let optax = (1 + tax) * 10;
      record.tax = Number(Number(record.subtotal || 0) * tax).toFixed(2);
      this.records[index] = record;
    },
    removeRecord(record, index) {
      this.records.splice(index, 1);
    },
    async searchJob(query = "") {
      try {
        this.loading = true;
        const res = await axios.get(`/v2/jobs/`, {
          params: {
            search: query,
            client_id: this.clientId,
            entity_id: this.entityId,
          },
        });
        this.loading = false;
        console.log(res.data);
        this.jobs = res.data.data;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    async storeInvoice() {
      try {
        this.loading = true;
        let passed = this.dueDate && this.clientId && this.type;

        if (passed && this.type == 1 && this.totalInvoice < 0) {
          this.$confirm(
            "Regular invoice cannot have negative total",
            "Confirm",
            {
              distinguishCancelAndClose: true,
              confirmButtonText: "Save as Credit Note",
              cancelButtonText: "Cancel, I want to fix it",
            }
          )
            .then(async () => {
              this.type = 2;
              const res = await this.sendRequest();
              this.loading = false;
              console.log(res.data);
              window.location.replace(`/invoices/${res.data.id}`);
              window.history.pushState(null, null, `/invoices/${res.data.id}`);
            })
            .catch((action) => {
              this.loading = false;
            });
        } else if (passed) {
          // only passed
          const res = await this.sendRequest();
          console.log(res.data);
          this.loading = false;
          window.location.replace(`/invoices/${res.data.id}`);
          window.history.pushState(null, null, `/invoices/${res.data.id}`);
        } else {
          this.loading = false;
          alert("Please fill out all fields");
        }
      } catch (err) {
        console.error(err);
      }
    },
    addRecord() {
      this.records.push({
        completed_at: "",
        notes: "",
        subtotal: 0.0,
        tax: 0.0,
        total: 0.0,
        job_id: null,
      });
    },
    async sendRequest() {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await axios.post(this.endpoint, {
            reference: this.reference,
            due_date: this.dueDate,
            client_id: this.clientId,
            entity_id: this.entityId,
            records: this.records,
            type: this.type,
          });

          resolve(res);
        } catch (err) {
          reject(err);
        }
      });
    },
  },
  computed: {
    invoiceTypes() {
      return Object.keys(InvoiceType).map((key) => {
        return { id: InvoiceType[key].id, name: InvoiceType[key].name };
      });
    },
    totalInvoice() {
      let total = 0;
      this.records.forEach((record) => {
        total += Number(record.subtotal);
      });

      return total;
    },
  },
};
</script>
