<template>
  <ul class="dropdown-menu">
    <li>
      <a href="#" @click.prevent="sendInvoices"> Send Invoice with PDF & CSV</a>
    </li>
    <li>
      <a href="#" @click.prevent="sendInvoices(true, false)">
        Send Invoice with PDF</a
      >
    </li>
    <li>
      <a href="#" @click.prevent="sendInvoices(false, true)">
        Send Invoice with CSV</a
      >
    </li>
  </ul>
</template>
<script>
export default {
  data() {
    return {
      ids: [],
    };
  },
  mounted() {
    document.addEventListener("bulk-send-checkbox", (value) =>
      this.listener(value)
    );
  },
  methods: {
    listener(value) {
      this.ids = value.detail.invoice_ids;
    },
    async sendInvoices(pdf = true, csv = true) {
      this.$confirm("Are you sure  to send this invoices?", "Warning", {
        confirmButtonText: "Send",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(async (response) => {
          try {
            const res = await axios.post(`/v2/invoices/`, {
              invoice_ids: this.ids,
              send_pdf: pdf,
              send_csv: csv,
            });
            this.$message({
              showClose: true,
              message: "Invoice Sent",
              type: "success",
            });
          } catch (err) {
            console.error(err);
            this.$message({
              showClose: true,
              message: "Error Invoice Sending",
              type: "error",
            });
          }
        })
        .catch((_) => {});
    },
  },
};
</script>
