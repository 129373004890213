<template>
  <div style="display: flex; justify-content:space-between; align-item:center;">
    <input type="hidden" :name="name" :value="price" required />
    <el-input
      id="price"
      placeholder="Price"
      v-model="price"
      type="number"
      style="width:100%;"
      :disabled="disabled"
      required
      step="0.01"
      min="1"
    >
      <template slot="prepend" v-if="isCreditNote">-</template>
    </el-input>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    initial_disabled: {
      type: [String, Number, Boolean],
    },
    initial_job: {
      type: Object,
      required: false,
      default: () => {
        return {
          price: 1,
          is_credit_note: false,
        };
      },
    },
  },
  data() {
    return {
      price: this.initial_job.price,
      disabled: this.initial_disabled,
      isCreditNote: this.initial_job.is_credit_note || false,
    };
  },
  mounted() {
    document.addEventListener("set-credit-note", (value) =>
      this.setCreditNote(value)
    );
    if (this.price < 0) this.price = -1 * this.price;
    this.disabled = !!this.disabled;
  },
  methods: {
    setCreditNote(value) {
      this.isCreditNote = value.detail.is_credit_note;
    },
  },
};
</script>
