<template>
  <div>
    <el-dialog
      title="Download Forecasts"
      :visible.sync="isOpen"
      width="80%"
      :before-close="close"
    >
      <monthly-forecast-table
        :vendor="vendor"
        v-if="vendor"
        v-loading="loading"
      >
      </monthly-forecast-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="isOpen = false">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import VdtnetTable from "vue-datatables-net";
import axios from "axios";
import MonthlyForecastTable from "./MonthlyForecastTable";
export default {
  props: {},
  components: {
    VdtnetTable,
    MonthlyForecastTable,
  },
  data() {
    return {
      isOpen: false,
      vendor: null,
      loading: false,
    };
  },
  mounted() {
    window.addEventListener("open-forecast", this.open);
  },
  beforeDestroy() {
    window.removeEventListener("open-forecast", this.open);
  },
  methods: {
    async getVendor(id) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await axios.get(`/v2/vendors/${id}`);
          resolve(res);
        } catch (err) {
          reject(err);
        }
      });
    },
    async open(e) {
      try {
        this.loading = true;
        const data = await this.getVendor(e.detail.vendor_id);
        this.vendor = data.data;
        this.isOpen = true;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>
