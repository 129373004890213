<template>
  <div>
    <vdtnet-table
      id="records_table"
      :fields="fields"
      :opts="opts"
      ref="paused_jobs_table"
      class-name="table table-bordered nowrap w-100"
    >
    </vdtnet-table>
  </div>
</template>
<script>
import VdtnetTable from "vue-datatables-net";
export default {
  props: {
  },
  components: {
    VdtnetTable,
  },
  data() {
    return {
      fields: {
        id: { label: "ID" },
        "job.name": {
          label: "JOB NAME",
          sortable: false,
        },
        comments: {
          label: "COMMENTS",
          sortable: false,
        },
        user: {
          label: "User",
          sortable: false,
          render: function(data, type, row, meta) {
            return row.user
              ? `${row.user.first_name} ${row.user.first_name}`
              : "N/A";
          },
        },
        start_at: {
          label: "Start Date",
          sortable: false,
          render: function(data, type, row, meta) {
            return row.start_at
              ? moment(row.start_at, "YYYY-MM-DD").format(
                  "DD/MM/Y"
                )
              : "N/A";
          },
        },
        end_at: {
          label: "End Date",
          sortable: false,
          render: function(data, type, row, meta) {
            return row.end_at
              ? moment(row.end_at, "YYYY-MM-DD").format(
                  "DD/MM/Y"
                )
              : "N/A";
          },
        },
        created_at: {
          label: "Created Date",
          sortable: false,
          render: function(data, type, row, meta) {
            return row.created_at
              ? moment(row.created_at, "YYYY-MM-DD HH:mm:ss").format(
                  "DD/MM/Y HH:mm:ss"
                )
              : "N/A";
          },
        },
      },
      opts: {
        ajax: {
          url: `/v2/paused-jobs`,
          type: "GET",
        },
        searching: true,
        searchDelay: 1500,
        serverSide: true,
        processing: true,
      },
      quickSearch: "",
    };
  },
  methods: {},
};
</script>
