<template>
  <el-dialog
    title="Add Line Item"
    :visible.sync="openDialog"
    v-loading="loading"
  >
    <div class="row">
      <div class="col-md-4">
        Notes
      </div>
      <div class="col-md-8">
        <el-input v-model="lineItem.notes"> </el-input>
      </div>
      <div class="col-md-4" style="margin-top:10px;">
        Completed At
      </div>
      <div class="col-md-8" style="margin-top:10px;">
        <el-date-picker
          v-model="lineItem.completed_at"
          type="date"
          style="width:100%;"
          format="dd/MM/yyyy"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="col-md-4" style="margin-top:10px;">
        Subtotal
      </div>
      <div class="col-md-8" style="margin-top:10px;">
        <el-input v-model="lineItem.subtotal" type="number"> </el-input>
      </div>
      <div class="col-md-4" style="margin-top:10px;">
        Tax
      </div>
      <div class="col-md-8" style="margin-top:10px;">
        <el-input v-model="lineItem.tax" type="number"> </el-input>
      </div>
      <div class="col-md-4" style="margin-top:10px;">
        Total
      </div>
      <div class="col-md-8" style="margin-top:10px;">
        <el-input v-model="total" type="number"> </el-input>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="openDialog = false">Cancel</el-button>
      <el-button type="primary" @click="save">Confirm</el-button>
    </span>
  </el-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lineItem: {
        subtotal: 0,
        total: 0,
        tax: 0,
        notes: "",
        completed_at: null,
      },
      openDialog: false,
      loading: false,
    };
  },
  methods: {
    async save() {
      try {
        this.loading = true;
        const res = await axios.post(
          `/v2/invoices/${this.invoice.id}/invoice_job_records`,
          this.lineItem
        );
        this.$emit("added-line-item", res.data);
        this.lineItem = {
          subtotal: 0,
          total: 0,
          tax: 0,
          notes: "",
        };
        this.$message({
          message: "Invoice line item added",
          type: "success",
        });
        this.loading = false;
        this.openDialog = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
        this.$message({
          message: "Invoice line item cannot be added",
          type: "error",
        });
      }
    },
  },
  computed: {
    total: {
      set(value) {
        this.lineItem.total = Number(value);
      },
      get() {
        const total =
          Number(this.lineItem.subtotal) + Number(this.lineItem.tax);
        this.lineItem.total = total;
        return this.lineItem.total;
      },
    },
  },
  watch: {
    open(to, from) {
      this.openDialog = to;
    },
    openDialog(to, from) {
      this.$emit("update:open", to);
    },
  },
};
</script>
