<template>
  <div>
    <vdtnet-table
      id="records_table"
      :fields="fields"
      :opts="opts"
      ref="records"
      class-name="table table-bordered nowrap w-100"
    >
    </vdtnet-table>
  </div>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
export default {
  props: {
    job: {
      type: Object,
      required: true,
    },
    isVendor: {
      type: Boolean,
      required: true,
    },
    entity: {
      type: Object,
    },
    client: {
      type: Object,
    },
    round: {
      type: Object,
    },
    linkTrail: {
      type: String,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    VdtnetTable,
  },
  data() {
    return {
      fields: {
        id: { label: "ID" },
        due_date: {
          label: "DUE DATE",
          sortable: true,
          render: function (data, type, row, meta) {
            return moment(row.due_date, "YYYY-MM-DD").format("DD/MM/YYYY");
          },
        },
        vendor: {
          label: "VENDOR",
          sortable: false,
          render: function (data, type, row, meta) {
            if (row.vendor) {
              return `${row.vendor.user.first_name} ${row.vendor.user.last_name}`;
            } else {
              return "No vendor";
            }
          },
        },
        ...(this.isAdmin
          ? {
              // admin only field
              price: {
                sortable: true,
                label: "Price",
                render: function (data, type, row, meta) {
                  return `$${row.price}`;
                },
              },
            }
          : {}),
        status: {
          label: "STATUS",
          sortable: false,
          render: function (data, type, row, meta) {
            if (row.is_upcoming) {
              return ` <td><span class="label label-warning">${row.status}<span></td>`;
            } else if (row.is_overdue) {
              return ` <td><span class="label label-danger">${row.status}<span></td>`;
            } else if (row.is_new) {
              return ` <td><span class="label label-primary">${row.status}<span></td>`;
            } else if (
              row.is_complete ||
              row.is_accepted ||
              row.status == "Uploaded"
            ) {
              return ` <td><span class="label label-success">${row.status}<span></td>`;
            } else if (row.status == "Missed") {
              return ` <td><span class="label label-warning">${row.status}<span></td>`;
            }
          },
        },
        ...(this.job.auto_complete
          ? {
              autocomplete: {
                label: "Autocomplete Running At",
                sortable: false,
                render: function (data, type, row, meta) {
                  if (row.record_queues[0]) {
                    return moment(
                      row.record_queues[0].create_record_at,
                      "YYYY-MM-DD"
                    ).format("DD/MM/YYYY");
                  } else if (
                    row.status == "Upcoming" &&
                    !row.record_queues[0]
                  ) {
                    return "Not in queue yet";
                  } else if (
                    row.status == "Complete" &&
                    !row.record_queues[0]
                  ) {
                    return moment(row.date_complete, "YYYY-MM-DD").format(
                      "DD/MM/YYYY"
                    );
                  }
                  return "N/A";
                },
              },
            }
          : null),
        created_at: {
          label: "CREATED AT",
          sortable: false,
          render: (data, type, row, meta) => {
            let link = `/jobs/${this.job.id}/records/${row.id}`;

            switch (this.linkTrail) {
              case "admin.jobs.records.show":
                link = `/jobs/${this.job.id}/records/${row.id}`;
                break;
              case "admin.rounds.jobs.records.show":
                link = `/rounds/${this.round.id}/jobs/${this.job.id}/records/${row.id}`;
                break;
              case "admin.entities.jobs.records.show":
                link = `/entities/${this.entity.id}/jobs/${this.job.id}/records/${row.id}`;
                break;
              case "admin.clients.entities.jobs.records.show":
                link = `/clients/${this.client.id}/entities/${this.entity.id}/jobs/${this.job.id}/records/${row.id}`;
                break;
              default:
                link = `/jobs/${this.job.id}/records/${row.id}`;
                break;
            }

            return `<a href="${link}">
                     ${moment(row.created_at, "YYYY-MM-DD HH:mm:ss").format(
                       "DD/MM/Y HH:mm:ss"
                     )}</a>
                <a href="${link}" class="btn btn-default btn-sm capt-edit-btn">View</a>`;
          },
        },
      },
      opts: {
        ajax: {
          url: `/v2/jobs/${this.job.id}/records`,
          type: "GET",
        },
        searching: true,
        searchDelay: 1500,
        serverSide: true,
        processing: true,
      },
      quickSearch: "",
    };
  },
  methods: {},
};
</script>
