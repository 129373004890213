<template>
  <div>
    <form
      class="form-inline"
      style="display:flex; justify-content:flex-end; align-items:center; margin-bottom:20px;"
      @submit.stop.prevent="doSearch"
    >
      <div class="input-group" style="display:flex; align-items:center;">
        <input
          v-model="quickSearch"
          type="search"
          placeholder="Quick search"
          style="margin-right:10px;"
          class="form-control"
        />
        <div class="input-group-append">
          <button type="submit" class="btn btn-outline-secondary">
            <i class="mdi mdi-magnify" /> Go
          </button>
        </div>
      </div>
    </form>
    <vdtnet-table
      id="completed-jobs-table"
      :fields="fields"
      :opts="opts"
      ref="completed_job_table"
      class-name="table table-bordered nowrap w-100"
    >
    </vdtnet-table>
  </div>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
    user_roles: {
      type: [Array, Object],
      required: true,
    },
  },
  components: {
    VdtnetTable,
  },
  data() {
    return {
      fields: {
        id: { label: "ID" },
        "job.name": {
          label: "NAME",
          sortable: false,
        },
        status: { label: "STATUS" },
        location: {
          label: "LOCATION",
          sortable: false,
          searchable: false,
          render: function(data, type, row, meta) {
            return row.job.address ? row.job.address.full_address : null;
          },
        },
        "job.type": { label: "TYPE" },
        frequency: {
          label: "FREQUENCY",
          sortable: false,
          searchable: false,
          render: function(data, type, row, meta) {
            return row.job.frequency_rules.length
              ? `${row.job.frequency_rules[0].interval} ${row.job.frequency_rules[0].freq}`
              : "N/A";
          },
        },
        price: {
          label: "PRICE",
          searchable: false,
          render: function(data, type, row, meta) {
            return `$${row.price}`;
          },
        },
        "": {
          label: "",
          sortable: false,
          searchable: false,
          render: (data, type, row, meta) => {
            return `<a type="button" class="btn btn-default" href="/jobs/${row.job_id}/records/${row.id}">Proof of Service</a>`;
          },
        },
      },
      opts: {
        ajax: {
          url: `/v2/dashboard/companies/${this.company.id}/admin/jobs`,
          type: "GET",
        },
        searching: true,
        searchDelay: 1500,
        serverSide: true,
        processing: true,
      },
      quickSearch: "",
    };
  },
  methods: {
    doSearch() {
      this.$refs.completed_job_table.search(this.quickSearch);
    },
  },
  computed: {
    roles() {
      return this.user_roles.map((role) => role.name);
    },
  },
};
</script>
