<template>
  <div>
    <el-dialog
      title="Create Vendor"
      :visible.sync="isOpen"
      :before-close="close"
    >
      <div class="row">
        <div class="col-md-4 mb-3 flex-center">
          <span class="title">Vendor Name</span>
        </div>
        <div class="col-md-8 mb-3 flex-column">
          <p
            class="text-danger error-text"
            v-if="form.errors.has('vendor_name')"
          >
            {{ form.errors.get("vendor_name")[0] }}
          </p>
          <el-input
            v-model="vendor.vendor_name"
            :class="{ 'is-error': form.errors.has('vendor_name') }"
          >
          </el-input>
        </div>
        <div class="col-md-4 mb-3 flex-center">
          <span class="title">Address Line 1</span>
        </div>
        <div class="col-md-8 mb-3 flex-column">
          <p
            class="text-danger error-text"
            v-if="form.errors.has('address.address_line_1')"
          >
            {{ form.errors.get("address.address_line_1")[0] }}
          </p>
          <el-input
            v-model="vendor.address.address_line_1"
            :class="{ 'is-error': form.errors.has('address.address_line_1') }"
          >
          </el-input>
        </div>
        <div class="col-md-4 mb-3 flex-center">
          <span class="title">Address Line 2</span>
        </div>
        <div class="col-md-8 mb-3 flex-center">
          <el-input v-model="vendor.address.address_line_2"> </el-input>
        </div>
        <div class="col-md-4 mb-3 flex-center">
          <span class="title">Postcode</span>
        </div>
        <div class="col-md-8 mb-3 flex-column">
          <p
            class="text-danger error-text"
            v-if="form.errors.has('address.postcode')"
          >
            {{ form.errors.get("address.postcode")[0] }}
          </p>
          <el-input
            v-model="vendor.address.postcode"
            type="number"
            :class="{ 'is-error': form.errors.has('address.postcode') }"
          >
          </el-input>
        </div>
        <div class="col-md-4 mb-3 flex-center">
          <span class="title">Suburb</span>
        </div>
        <div class="col-md-8 mb-3 flex-column">
          <p
            class="text-danger error-text"
            v-if="form.errors.has('address.suburb')"
          >
            {{ form.errors.get("address.suburb")[0] }}
          </p>
          <el-input
            v-model="vendor.address.suburb"
            :class="{ 'is-error': form.errors.has('address.suburb') }"
          >
          </el-input>
        </div>
        <div class="col-md-4 mb-3 flex-center">
          <span class="title">State</span>
        </div>
        <div class="col-md-8 mb-3 flex-column">
          <p
            class="text-danger error-text"
            v-if="form.errors.has('address.suburb')"
          >
            {{ form.errors.get("address.suburb")[0] }}
          </p>
          <el-input
            v-model="vendor.address.state"
            :class="{ 'is-error': form.errors.has('address.state') }"
          >
          </el-input>
        </div>
        <div class="col-md-4 mb-3 flex-center">
          <span class="title">Country</span>
        </div>
        <div class="col-md-8 mb-3 flex-column">
          <p
            class="text-danger error-text"
            v-if="form.errors.has('address.country')"
          >
            {{ form.errors.get("address.country")[0] }}
          </p>
          <el-input
            v-model="vendor.address.country"
            disabled
            :class="{ 'is-error': form.errors.has('address.country') }"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isOpen = false">Cancel</el-button>
        <el-button type="primary" @click="saveVendor">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Form from "form-backend-validation";
export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      form: new Form(),
      isOpen: false,
      userId: null,
      vendor: {
        vendor_name: "",
        address: {
          address_line_1: "",
          address_line_2: "",
          suburb: "",
          postcode: "",
          state: "",
          country: this.company.country,
        },
      },
    };
  },
  mounted() {
    window.addEventListener("open-create-vendor", this.open);
  },
  beforeDestroy() {
    window.removeEventListener("open-create-vendor", this.open);
  },
  methods: {
    async saveVendor() {
      try {
        this.form.clear();
        this.vendor.address.country_code =
          this.vendor.address.country == "Australia" ? "AU" : "NZ";

        const res = await axios.post(
          `/v2/companies/${this.company.id}/users/${this.userId}/vendors`,
          this.vendor
        );
        this.isOpen = false;
        window.location.replace(`/vendors/${res.data.id}`);
        window.history.pushState(null, null, `/vendors/${res.data.id}`);
      } catch (err) {
        console.error(err);
        this.form.errors.record(err.response.data);
      }
    },
    async open(e) {
      try {
        this.userId = e.detail.user_id;
        this.isOpen = true;
      } catch (err) {
        console.error(err);
      }
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>
<style lang="sass" scoped>
.flex-center
  display: flex
  align-items: center
  justify-content: flex-start

.flex-column
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start

.title
  font-weight: 550

.error-text
  margin-bottom: 0px

.mb-3
    margin-bottom: 20px
</style>
