<template>
  <div v-loading="loading">
    <div class="form-group col-md-6" style="">
      <label for="sale_rep1_id" class="col-md-3"> Sale Representative 1</label>
      <div class="col-md-9">
        <el-select
          style="width:100%"
          v-model="job.sale_rep1_id"
          :disabled="disabled"
          placeholder="Select Sale Representative 1"
        >
          <el-option
            v-for="user in admins"
            :key="user.id"
            :value="user.id"
            :label="`${user.first_name} ${user.last_name} - ${user.email}`"
          >
          </el-option>
        </el-select>
        <input
          type="hidden"
          name="job[sale_rep1_id]"
          :value="job.sale_rep1_id"
        />
      </div>
    </div>
    <div class="form-group col-md-6">
      <label for="sale_rep1_percentage" class="col-md-3">
        Sale Representative 1 Percentage</label
      >
      <div class="col-md-9">
        <el-input
          v-model="job.sale_rep1_percentage"
          placeholder="0"
          type="number"
          :disabled="disabled"
          name="job[sale_rep1_percentage]"
        >
        </el-input>
      </div>
    </div>
    <div class="form-group col-md-6">
      <label for="sale_rep2_id" class="col-md-3"> Sale Representative 2</label>
      <div class="col-md-9">
        <el-select
          style="width:100%"
          v-model="job.sale_rep2_id"
          :disabled="disabled"
          placeholder="Select Sale Representative 1"
        >
          <el-option
            v-for="user in admins"
            :key="user.id"
            :value="user.id"
            :label="`${user.first_name} ${user.last_name} - ${user.email}`"
          >
          </el-option>
        </el-select>
        <input
          type="hidden"
          name="job[sale_rep2_id]"
          :value="job.sale_rep2_id"
        />
      </div>
    </div>
    <div class="form-group col-md-6">
      <label for="sale_rep2_percentage" class="col-md-3">
        Sale Representative 2 Percentage</label
      >
      <div class="col-md-9">
        <el-input
          v-model="job.sale_rep2_percentage"
          placeholder="0"
          type="number"
          :disabled="disabled"
          name="job[sale_rep2_percentage]"
        >
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initial_job: {
      type: Object,
      required: false,
      default: () => {
        return {
          sale_rep1_id: null,
          sale_rep2_id: null,
          sale_rep1_percentage: null,
          sale_rep2_percentage: null,
        };
      },
    },
    initial_disabled: {
      required: false,
    },
  },
  data() {
    return {
      admins: [],
      loading: false,
      job: this.initial_job,
      disabled: this.initial_disabled ? true : false,
    };
  },
  mounted() {
    this.getAdmins();
  },
  methods: {
    async getAdmins() {
      try {
        this.loading = true;
        const res = await axios.get(`/v2/organisations/1/users`);
        this.admins = res.data.users;
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },
  },
};
</script>
