<template>
  <div>
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <el-select
        v-model="job.auto_complete"
        style="width: 100%"
        :disabled="disabled"
      >
        <el-option
          v-for="(option, index) in options"
          :key="index"
          :label="option.label"
          :value="option.value"
        >
        </el-option>
      </el-select>
    </div>
    <input type="hidden" :value="job.auto_complete" :name="name" />
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    initial_job: {
      type: Object,
      required: false,
      default: () => {
        return {
          is_credit_note: false,
          type: "",
          auto_complete: false,
        };
      },
    },
    initial_disabled: {
      type: [String, Number, Boolean],
    },
  },
  mounted() {
    this.setCreditNoteListener();
    this.setGcListener();

    // only do the below if no job yet
    if (
      !this.initial_job.id &&
      (this.job.is_credit_note ||
        this.job.type.trim().toLowerCase().includes("general cleaning"))
    ) {
      this.disabled = true;
      this.job.auto_complete = 1;
    }
  },
  data() {
    return {
      disabled: this.initial_disabled ? true : false,
      job: this.initial_job,
      options: [
        { value: 0, label: "Off" },
        { value: 1, label: "On" },
      ],
    };
  },
  methods: {
    setGcListener() {
      document.addEventListener("set-general-cleaning", (value) => {
        if (value.detail.general_cleaning) {
          //   if set
          this.disabled = true;
          this.job.auto_complete = 1;
        } else {
          this.disabled = false;
        }
      });
    },
    setCreditNoteListener() {
      document.addEventListener("set-credit-note", (value) => {
        if (value.detail.is_credit_note) {
          //   if set
          this.disabled = true;
          this.job.auto_complete = 1;
        } else {
          this.disabled = false;
        }
      });
    },
  },
};
</script>
