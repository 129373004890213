<template>
  <div>
    <div class="form-group">
      <label class="control-label" for="effective_from">Effective From</label>
      <input v-model="effectiveFrom" type="hidden" name="effective_from" />
      <el-date-picker
        v-model="effectiveFrom"
        type="date"
        style="width:100%;"
        id="effective_from"
        format="dd/MM/yyyy"
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
    </div>
    <div class="form-group" v-if="rule.id">
      <label class="control-label" for="effective_from">Is Active</label>
      <input type="hidden" name="is_active" v-model="ruleJobStatus" />
      <el-select v-model="ruleJobStatus" style="width:100%">
        <el-option
          v-for="s in status"
          :key="s.value"
          :value="s.value"
          :label="s.label"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initial_rule: {
      type: Object,
      default: () => {
        return {
          pivot: {
            effective_from: null,
            is_active: null,
          },
        };
      },
    },
  },
  data() {
    return {
      rule: this.initial_rule,
      effectiveFrom: null,
      ruleJobStatus: null,
      status: [
        { label: "Activated", value: 1 },
        { label: "Pending Activation", value: 2 },
        { label: "Deactivated", value: 0 },
      ],
    };
  },
  mounted() {
    if (this.rule.pivot) {
      this.effectiveFrom = this.rule.pivot.effective_from;
      this.ruleJobStatus = this.rule.pivot.is_active;
    }
  },
};
</script>
