export default {
    none: {
        name: "None",
    },
    outstanding: {
        name: "Outstanding",
    },
    waiting: {
        name: "Waiting",
    },
    completed: {
        name: "Completed",
    },
    closed: {
        name: "Closed",
    },
};