<template>
  <div>
    <el-dialog
      title="Download Historical MCR"
      :visible.sync="isOpen"
      width="80%"
      :before-close="close"
    >
      <mcr-table :vendor="vendor" v-if="vendor" :key="vendor.id"> </mcr-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="isOpen = false">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import VdtnetTable from "vue-datatables-net";
import axios from "axios";
export default {
  props: {},
  components: {
    VdtnetTable,
  },
  data() {
    return {
      isOpen: false,
      vendor: null,
    };
  },
  mounted() {
    window.addEventListener("open-historical-mcr", this.open);
  },
  beforeDestroy() {
    window.removeEventListener("open-historical-mcr", this.open);
  },
  methods: {
    async getVendor(id) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await axios.get(`/v2/vendors/${id}`);
          resolve(res);
        } catch (err) {
          reject(err);
        }
      });
    },
    async open(e) {
      try {
        const data = await this.getVendor(e.detail.vendor_id);
        this.vendor = data.data;
        this.isOpen = true;
      } catch (err) {
        console.error(err);
      }
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>
