<template>
  <el-dialog title="Edit Jobs" :visible.sync="openDialog">
    <div class="row">
      <div class="col-md-4" style="margin-top:10px;">
        First Date of Service
      </div>
      <div class="col-md-8" style="margin-top:10px;">
        <el-date-picker
          v-model="job.first_service_date"
          type="date"
          style="width:100%;"
          format="dd/MM/yyyy"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="col-md-4" style="margin-top:10px;">
        Last Date of Service
      </div>
      <div class="col-md-8" style="margin-top:10px;">
        <el-date-picker
          v-model="job.last_service_date"
          type="date"
          style="width:100%;"
          format="dd/MM/yyyy"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="col-md-4" style="margin-top:10px;">
        Frequency Rule
      </div>
      <div class="col-md-4" style="margin-top:10px;">
        <el-select
          v-model="job.frequency_rule_id"
          style="width:100%;"
          placeholder="Job Frequency"
          filterable
        >
          <el-option
            v-for="rule in frequencyRules"
            :key="rule.id"
            :value="rule.id"
            :label="rule.rule_text"
          >
          </el-option>
        </el-select>
      </div>
      <div class="col-md-4" style="margin-top:10px;">
        <el-date-picker
          v-model="job.effective_from"
          type="date"
          style="width:100%;"
          placeholder="Rule Effective From"
          format="dd/MM/yyyy"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="openDialog = false">Cancel</el-button>
      <el-button type="primary" @click="save">Update</el-button>
    </span>
  </el-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: {},
  data() {
    return {
      openDialog: false,
      jobIds: [],
      frequencyRules: [],
      job: {
        first_service_date: null,
        last_service_date: null,
        frequency_rule_id: null,
        effective_from: null,
      },
    };
  },
  mounted() {
    this.getFrequencyRules();
    document.addEventListener("open-edit-modal", this.open);
  },
  beforeDestroy() {
    document.removeEventListener("open-edit-modal", this.open);
  },
  methods: {
    open() {
      this.openDialog = true;
    },
    async getFrequencyRules() {
      try {
        const res = await axios.get(`/v2/frequency_rules/`);
        this.frequencyRules = res.data;
      } catch (err) {
        console.error(err);
      }
    },
    async save() {
      try {
        const res = await axios.put("/v2/jobs", {
          job_ids: selectedRows,
          job: this.job,
        });
        console.log(res.data);
        this.openDialog = false;
      } catch (err) {
        console.error(err);
        var errorText = "";
        _.forEach(err.response.data, function(value) {
          errorText += "" + value + " ";
        });
        this.$message({
          type: "error",
          message: errorText,
        });
      }
    },
  },
  watch: {},
};
</script>
