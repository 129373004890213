<template>
  <div>
    <apexchart
      type="pie"
      width="380"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    completedJobsCount: {
      type: Number,
      required: true,
    },
    recordsCount: {
      type: Number,
      required: true,
    },
    missedCount: {
      type: Number,
      required: true,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [this.completedJobsCount, this.recordsCount, this.missedCount],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["Completed Jobs", "Upcoming Jobs", "Missed Jobs"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
};
</script>
