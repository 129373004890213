<template>
  <div>
    <el-button size="small" @click="isOpen = true" type="primary">
      Download Forecast
    </el-button>
    <el-dialog title="Download Forecasts" :visible.sync="isOpen" width="80%">
      <p>
        <small class="text-muted">
          The forecast summary in this view might not be the latest version and
          could be different compared to the export result, it is refreshed
          every 30 minutes.
        </small>
      </p>
      <vendor-monthly-forecast-table :vendor="vendor">
      </vendor-monthly-forecast-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="isOpen = false">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import VendorMonthlyForecastTable from "./VendorMonthlyForecastTable";
export default {
  props: {
    vendor: {
      type: Object,
      required: true,
    },
  },
  components: {
    VendorMonthlyForecastTable,
  },
  data() {
    return {
      isOpen: false,
      loading: false,
    };
  },
};
</script>
