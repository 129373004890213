<template>
  <el-dialog
    title="Update Reference Number"
    :visible.sync="openDialog"
    v-loading="loading"
  >
    <div class="row">
      <div class="col-md-4">
        Reference
      </div>
      <div class="col-md-8">
        <el-input v-model="initial_invoice.reference" placeholder="Reference #">
        </el-input>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="openDialog = false">Cancel</el-button>
      <el-button type="primary" @click="save">Confirm</el-button>
    </span>
  </el-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    initial_invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      openDialog: false,
      invoice: this.initial_invoice,
      loading: false,
    };
  },
  methods: {
    async save() {
      this.$confirm(
        "Are you sure you proceed with this? Because it will affect the submitted invoice version",
        "Warning",
        {
          confirmButtonText: "Update",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async (response) => {
          try {
            this.loading = true;
            await axios.put(`/v2/invoices/${this.invoice.id}`, this.invoice);
            this.loading = false;
            this.$message({
              showClose: true,
              message: "Invoice Updated",
              type: "success",
            });
            window.location.reload();
          } catch (err) {
            console.error(err);
            this.loading = false;
            this.$message({
              showClose: true,
              message: "Error Updating Invoice",
              type: "error",
            });
          }
        })
        .catch((_) => {});
    },
  },
  watch: {
    open(to, from) {
      this.openDialog = to;
    },
    openDialog(to, from) {
      this.$emit("update:open", to);
    },
  },
};
</script>
