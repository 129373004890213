<template>
  <div class="select-group-container">
    <el-select
      v-model="value"
      multiple
      filterable
      style="width: 100%;"
      remote
      reserve-keyword
      placeholder="Please enter a keyword"
      :remote-method="search"
      :loading="loading"
      clearable
      @clear="search"
    >
      <el-option
        v-for="vendor in vendors"
        :key="vendor.id"
        :label="
          vendor.vendor_name ||
            `${vendor.user.first_name} ${vendor.user.last_name}`
        "
        :value="vendor.id"
      >
      </el-option>
    </el-select>
    <input
      type="hidden"
      :name="name"
      v-for="(v, index) in value"
      :key="index"
      :value="v"
    />
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    companyId: {
      required: true,
      type: Number,
    },
    name: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      value: null,
      vendors: [],
      loading: false,
    };
  },
  methods: {
    async search(query = "") {
      try {
        this.loading = true;
        const res = await axios.get(`/v2/companies/${this.companyId}/vendors`, {
          params: {
            query: query,
            page: 1,
          },
        });

        console.log(res.data.data);
        this.vendors = res.data.data;
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.select-group-container
    display: flex
    align-items: center
    justify-content: flex-start
</style>
