<template>
  <div class="col-lg-6">
    <div class="panel panel-default">
      <div class="panel-heading capt-dash-heading">
        <h2 class="dash-panel-heading">LATEST SAFETY / QUALITY TEST</h2>
      </div>
      <div class="panel-body">
        <div class="col-lg-12">
          <form
            class="form-inline"
            style="display:flex; justify-content:flex-end; align-items:center; margin-bottom:20px;"
            @submit.stop.prevent="doSearch"
          >
            <div class="input-group" style="display:flex; align-items:center;">
              <input
                v-model="quickSearch"
                type="search"
                placeholder="Quick search"
                style="margin-right:10px;"
                class="form-control"
              />
              <div class="input-group-append">
                <button type="submit" class="btn btn-outline-secondary">
                  <i class="mdi mdi-magnify" /> Go
                </button>
              </div>
            </div>
          </form>
          <vdtnet-table
            id="problem-record-table"
            :fields="fields"
            :opts="opts"
            ref="problem_record_table"
            class-name="table table-bordered nowrap w-100"
          >
          </vdtnet-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VdtnetTable from "vue-datatables-net";
export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  components: {
    VdtnetTable,
  },
  data() {
    return {
      fields: {
        status: { label: "Status" },
        "job.name": {
          label: "Job Name",
          render: function(data, type, row, meta) {
            return row.job.name;
          },
        },
        "job.type": {
          label: "Job Type",
          render: function(data, type, row, meta) {
            return row.job.type;
          },
        },
        frequency: {
          label: "Frequency",
          sortable: false,
          searchable: false,
          render: function(data, type, row, meta) {
            return row.job.active_rule.length > 0
              ? `${row.job.active_rule[0].interval} ${row.job.active_rule[0].freq}`
              : null;
          },
        },
        outcome: {
          label: "Outcome",
          render: function(data, type, row, meta) {
            return row.complaint_status;
          },
        },
        actions: {
          label: "ACTIONS",
          searchable: false,
          sortable: false,
          render: function(data, type, row, meta) {
            return `<a class="btn btn-default btn-sm " type="button" aria-expanded="false" href="/jobs/${row.job.id}/records/${row.id}" style="color: #fff;">Proof of Service</a>`;
          },
        },
      },
      opts: {
        ajax: {
          url: `/v2/dashboard/companies/${this.company.id}/admin/problem_records`,
          type: "GET",
        },
        searching: true,
        searchDelay: 1500,
        serverSide: true,
        processing: true,
      },
      quickSearch: "",
      isOpen: false,
    };
  },
  methods: {
    reload() {
      this.$refs.problem_record_table.reload();
    },
    doSearch() {
      this.$refs.problem_record_table.search(this.quickSearch);
    },
  },
};
</script>
