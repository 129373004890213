<template>
  <div class="col-md-6 col-sm-6">
    <!-- announcements -->
    <div
      class="panel panel-default capt-dash-news capt-info-charts"
      style="overflow-y: auto;"
    >
      <div class="panel-heading capt-dash-heading">
        <h2 class="dash-panel-heading">CCNW NOTIFICATIONS</h2>
      </div>

      <div class="panel-body" v-loading="loading">
        <!-- repeat for news feed -->

        <div
          class="row row-notification"
          v-for="notification in notifications"
          :key="notification.id"
        >
          <div class="col-sm-12">
            <p class="dash-news-date">
              AEST -
              {{
                moment(notification.created_at, "YYYY-MM-DD HH:mm:ss").format(
                  "DD/MM/Y HH:mm:ss"
                )
              }}
            </p>
          </div>
          <div class="col-sm-12">
            <p>{{ notification.message }}</p>
          </div>
          <div class="col-sm-12">
            <a
              type="button"
              v-if="notification.ref_type.ref == 'job'"
              :href="`jobs/${notification.ref_id}`"
              class="btn btn-default"
              >Read More</a
            >

            <a
              type="button"
              v-else-if="notification.ref_type.ref == 'round'"
              :href="`rounds/${notification.ref_id}`"
              class="btn btn-default"
              >Read More</a
            >
          </div>
        </div>

        <div class="row" v-if="notifications.length == 0">
          <div class="col-sm-12">
            <p>No notifications</p>
          </div>
        </div>

        <!-- end repeat, first div -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    source: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      notifications: [],
      loading: false,
    };
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const res = await axios.get(this.source);
        this.notifications = res.data;
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.row-notification
    margin-bottom: 10px
    padding-bottom: 10px
    border-bottom: 1px #efefef solid
</style>
